<template lang="pug">
  .new-page
    .app-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .header.app-header
      h1.nio-h1.text-primary-darker New Dataset
      .controls
    NioStepper(
      v-if="steps"
      :ordered-steps="steps"
      :current-step="currentStep"
      :completed-steps="completedSteps"
      :loading="loading"
      final-step-label="Activate"
      @nextStep="nextStep"
      @previousStep="previousStep"
      @submit="createDataset"
      @stepSelected="stepSelected($event)"
    )
      NioStep(
        v-if="steps.includes('dataset')"
        :valid="stepPayloads.dataset !== null"
        :summary="makeStepSummary('dataset')"
        step-name="dataset"
        simple-summary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          DatasetStep(
            :current-step="currentStep"
            :completed-summary="makeStepSummary('dataset')"
            :completed-steps="completedSteps"
            @stepPayloadChanged="updatePayload('dataset', $event)"
            @setStepIncomplete="setStepIncomplete('dataset')"
            @changeDatasetMethodType="changeDatasetMethodType"
          )
      NioStep(
        v-if="steps.includes('file info')"
        :valid="stepPayloads['file info'] !== null && stepPayloads['file info'] !== undefined"
        :summary="makeStepSummary('file info')"
        step-name="file info"
        simple-summary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          FileinfoStep(
            :current-step="currentStep"
            :pre-populate="prePopulateFileInfo"
            :payload="stepPayloads['file info']"
            :file="fileUpload"
            :completed-summary="makeStepSummary('file info')"
            :completed-steps="completedSteps"
            :disable-controls="editingFileInfo === false"
            :external-editing-trigger="editingFileInfo"
            @stepPayloadChanged="updatePayload('file info', $event)"
            @setStepIncomplete="setStepIncomplete('file info')"
            @changeFileType="changeFileType"
            @editSelected="editFileInfo"
          )
      NioStep(
        :valid="fieldsValid"
        :summary="makeStepSummary('fields')"
        step-name="fields"
        simple-summary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          FieldsStep(
            :current-step="currentStep"
            :current-step-payload="stepPayloads"
            :pre-populate="prePopulateFields"
            :attributes="attributes"
            :completed-summary="makeStepSummary('fields')"
            @attributeMappingsChanged="updateAttributeMappings($event)"
            @stepPayloadChanged="updatePayload('fields', $event)"
            @setStepIncomplete="setStepIncomplete('fields')"
          )
      NioStep(
        :valid="stepPayloads.activate !== null"
        :summary="makeStepSummary('activate')"
        step-name="activate"
        simple-summary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          ReviewStep(
            v-if="prepReview"
            :review-data="prepReview"
            :current-step="currentStep"
            :completed-summary="makeStepSummary('activate')"
            @stepPayloadChanged="updatePayload('activate', $event)"
            @setStepIncomplete="setStepIncomplete('activate')"
          )
    NioDialog(
      v-model="errorDialog" 
    )
      ErrorDialog(
        @close="errorDialog = false"
      )
    NioDialog(
      v-model="successDialog" 
    )
      SuccessDialog(
        @action="handleSuccessDialogAction($event)"
      )
    NioDialog(
      v-model="ingestionUploadDialog" 
    )
      IngestionUploadDialog(
        :dataset="createdDataset"
        back-button-label="View datasets"
        @close="handleSuccessDialogAction('datasets')"
      )
    NioDialog(
      v-model="attributeMappingsErrorDialog" 
    )
      AttributeMappingsErrorDialog(
        :errors="attributeMappingsErrors"
        :forNewDataset="true"
        @action="handleSuccessDialogAction($event)"
      )        
</template>

<script>

import DatasetStep from './steps/dataset/DatasetStep'
import FileinfoStep from './steps/fileinfo/FileInfoStep'
import FieldsStep from './steps/fields/FieldsStep'
import ReviewStep from './steps/review/ReviewStep'
import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { formatCurrency } from '@/modules/helpers'
import ErrorDialog from './ErrorDialog'
import SuccessDialog from './SuccessDialog'
import IngestionUploadDialog from '@/shared/components/IngestionUploadDialog'
import { mapGetters, mapActions } from 'vuex'
import { checkFieldNamesFromJson } from '@/shared/utils/datasetConversions'
import { replacePropertyRefs } from '@narrative.io/tackle-box/src/modules/app/schema/attributeModule'
import { makeAttributeMappings } from '@/modules/mappingsModule'
import AttributeMappingsErrorDialog from '@/shared/components/attribute-mappings/AttributeMappingsErrorDialog'

export default {
  components: { 
    DatasetStep, 
    FileinfoStep, 
    FieldsStep, 
    ReviewStep, 
    ErrorDialog, 
    SuccessDialog, 
    IngestionUploadDialog,
    AttributeMappingsErrorDialog
  },
  data: () => ({
    steps: null,
    currentStep: null,
    completedSteps: [],
    filters: null,
    filtersValid: true,
    schema: null,
    datasets: null,
    reviewPreview: null,
    fileInfoValid: false,
    fileUpload: null,
    stepPayloads: {
      dataset: null,
      'file info': null,
      fields: null,
      activate: null
    },
    loading: false,
    tags: ['test 1', 'test 2'],
    numDatasets: null,
    prePopulateFields: null,
    prePopulateFileInfo: null,
    errorDialog: false,
    successDialog: false,
    lastDatasetType: null,
    editingFileInfo: false,
    ingestionUploadDialog: false,
    createdDataset: null,
    attributes: null,
    attributeMappings: null,
    attributeMappingsErrorDialog: false,
    attributeMappingsErrors: null,
    defaultErrorMessage: 'There was an error creating your dataset. If the problem persists, please contact a member of the Narrative support team'
  }),
  computed: {
    ...mapGetters(['getFieldsValidation', 'getFieldsAsArray']),
    fieldsValid() {
      return this.getFieldsAsArray?.length > 0 && !this.getFieldsValidation.errors.find(error => error.valid === false)
    },
    prepReview() {
      let rtn = {
        schema: {
          file_config: {}
        }
      }
      if (this.stepPayloads.dataset) {
        rtn.name = this.stepPayloads.dataset.name
        rtn.description = this.stepPayloads.dataset.description
        rtn.tags = this.stepPayloads.dataset.tags
        rtn.status = 'draft'
      }
      if (this.stepPayloads['file info']) {
        rtn.write_mode = this.stepPayloads['file info'].writeMode
        rtn.schema.file_config.type = this.stepPayloads['file info'].type
      }

      rtn.schema.properties = this.stepPayloads.fields ? this.stepPayloads['fields'].properties : [],
      rtn.schema.primary = this.stepPayloads.fields ? this.stepPayloads['fields'].primary : null
      rtn.fieldsSummary = this.stepPayloads.fields ? this.stepPayloads['fields'].fieldsCountString : null
      
      if (this.stepPayloads['file info']?.type === 'flat') {
        rtn.schema.file_config.delimiter = this.stepPayloads['file info'].delimiter === 'Custom' ? this.stepPayloads['file info'].customDelimiter : this.stepPayloads['file info'].delimiter
        rtn.schema.file_config.escape = this.stepPayloads['file info'].escapeCharacter
        rtn.schema.file_config.header = this.stepPayloads['file info'].incHeader
        rtn.schema.file_config.quote = this.stepPayloads['file info'].quote
      }
      if (rtn.schema.file_config?.type !== undefined) {
        return rtn
      }
      return null
    },
    prepFields() {
      return this.stepPayloads.fields
    }
  },
  watch: {
    filters: {
      deep: true,
      handler(val) {
        this.checkFiltersValid() 
      }
    }
  },
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    ...mapActions(['setFieldsFromJson']),
    openApiInit() {
      this.getAttributes()
      this.steps = ['dataset', 'file info', 'fields', 'activate']
      this.currentStep = 'dataset'
    },
    checkFiltersValid(filters) {
      this.$nextTick(() => {
        this.filtersValid = this.filters && this.filters.length && this.filters.find(filter => filter.valid === false) === undefined
      })
    },
    getAttributes() {
      this.$nioOpenApi.get('/attributes').then(res => {
        this.attributes = res.data?.records.map(attribute => {
          return {
            ...replacePropertyRefs(attribute, res.data.records),
            id: attribute.id
          }
        })
      })
    },
    updatePayload(step, payload) {
      switch(step) {
        case 'dataset':
          if (payload) {
            this.stepPayloads['dataset'] = payload.dataset
            this.prePopulateFileInfo = payload.fileinfo ? payload.fileinfo : undefined

            if (payload.fields) {
              this.prePopulateFields = payload.fields
            } else {
              if (!this.lastDatasetType || payload.type !== this.lastDatasetType) {
                this.prePopulateFields = { properties: {}}
              }
            }

            this.stepPayloads['fields'] = payload.fields ? payload.fields : undefined
            this.fileUpload = payload.file
            this.fileInfoValid = payload.fileinfo !== undefined
            this.lastDatasetType = payload.type

          } else {
            this.stepPayloads['dataset'] = null
          }
          break
        case 'file info':
          this.fileInfoValid = payload?.fileInfo !== undefined
          this.stepPayloads[step] = payload?.fileInfo !== undefined ? payload.fileInfo : undefined
          if (payload?.fields) {
            const fieldNamesValidation = checkFieldNamesFromJson({...payload.fields.properties})
            const validatedSchema = {...fieldNamesValidation.properties}
            this.invalidNames = fieldNamesValidation.invalidNames
            if (this.invalidNames?.length > 0) {
              this.invalidNamesDialog = true
            }
            this.stepPayloads['fields'] = { properties: validatedSchema }
            this.prePopulateFields = { properties: validatedSchema }
          }
          break
        default:
          this.stepPayloads[step] = payload
      }

      if (step !== 'file info' && this.stepPayloads.dataset?.type === 'file') {
        this.editingFileInfo = false
      }
    },
    checkFileInfoEditing() {
      if (this.currentStep !== 'file info' && this.lastDatasetType === 'file') {
        this.editingFileInfo = false
      }
    },
    nextStep() {
      if (!this.completedSteps.includes(this.currentStep)) {
        this.completedSteps.push(this.currentStep)
      }
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) + 1]
      this.scrollToStep(this.steps.indexOf(this.currentStep))
      this.checkFileInfoEditing()
    },
    previousStep() {
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) - 1]
      this.scrollToStep(this.steps.indexOf(this.currentStep))
      this.checkFileInfoEditing()
    },
    stepSelected(stepName) {
      this.currentStep = stepName
      this.checkFileInfoEditing()
    },
    setStepIncomplete(stepName) {
      
      const stepIndex = this.completedSteps.indexOf(stepName)
      this.completedSteps = this.completedSteps.filter((step, index) => {
        index < stepIndex
      })
      this.steps.map((step, index) => {
        if (index >= stepIndex) {
          this.stepPayloads[step] = null
        }
      })
    },
    editFileInfo() {
      this.editingFileInfo = true
    },
    formatPrice(price) {
      return formatCurrency(price)
    },
    stepComplete(stepName) {
      return this.completedSteps.includes(stepName)
    },
    writeModeConverted(val) {
      switch(val) {
        case 'overwrite':
          return 'Complete file updates'
        case 'append':
          return 'Incremental updates'
        default:
          return val
      }
    },
    makeStepSummary(stepName) {
      if (!this.stepPayloads[stepName]) {
        return
      }
      switch (stepName) {
        case 'dataset':
          return {
            title: `Dataset: ${this.stepPayloads.dataset.name}`
          }
        case 'fields':
          return {
            title: this.stepPayloads.fields && this.stepPayloads.fields.fieldsCountString ? this.stepPayloads.fields.fieldsCountString : ''
          }
        case 'file info':
          return {
            title: this.writeModeConverted(this.stepPayloads[stepName].writeMode)
          }
        case 'review':
          return {
            title: 'test',
          }
        default:
          break;
      }
    },
    formatSchema(properties, isArray) {
      let result = {}
      
      let curRequired = []
      let curUnsellable = []
      let curSensitive = []

      properties.forEach(field => {
        result[field.name] = {
          type: field.type
        }
        if (field.description?.length > 0) result[field.name].description = field.description
        if (field.approximate_cardinality) result[field.name].approximate_cardinality = field.approximate_cardinality
        if (field.fieldValues === 'enum' && field.enum?.length > 0) result[field.name].enum = field.enum
        const arrPath = field.path.split('/')
        arrPath.shift()
        if (!field.is_optional) {
          curRequired.push(arrPath[arrPath.length - 1])
        }
        if (!field.is_sellable) {
          curUnsellable.push(arrPath[arrPath.length - 1])
        }

        if (field.is_sensitive) {
          curSensitive.push(arrPath[arrPath.length - 1])
        }

        if (field.type === 'object' || field.type === 'array') {
          let childResult
          if (field.type === 'object' && field.properties.length > 0) {
            childResult = this.formatSchema(field.properties, false)     
            result[field.name].properties = childResult.properties
            if (childResult.required?.length > 0) result[field.name].required = childResult.required 
            if (childResult.unsellable?.length > 0) result[field.name].unsellable = childResult.unsellable 
            if (childResult.sensitive?.length > 0) result[field.name].sensitive = childResult.sensitive 
            
          } else if (field.type === 'array' && field.items.length === 1) {
            childResult = this.formatSchema(field.items, true)
            result[field.name].items = childResult.properties.nioArrayItemsField
          }
        }
      })

      return {
        properties: result,
        required: curRequired.flat().map(path => path.replaceAll('.nioArrayItemsField', '')),
        unsellable: curUnsellable.flat().map(path => path.replaceAll('.nioArrayItemsField', '')),
        sensitive: curSensitive.flat().map(path => path.replaceAll('.nioArrayItemsField', ''))
      }
    },
    async createDataset() {
      if (!this.loading) {
        this.loading = true
      let data = this.prepReview
      const formattedSchema = this.formatSchema(data.schema.properties)
    
      let dataset = {
        name: data.name,
        write_mode: data.write_mode,
        schema: {
          properties: formattedSchema.properties,
          file_config: data.schema.file_config,
          type: "object"
        }
      }
      if (formattedSchema.required?.length > 0) {
        dataset.schema.required = formattedSchema.required
      }
      if (formattedSchema.unsellable?.length > 0) {
        dataset.schema.unsellable = formattedSchema.unsellable
      }
      if (formattedSchema.sensitive?.length > 0) {
        dataset.schema.sensitive = formattedSchema.sensitive
      }
      if (data.schema.primary) {
        dataset.schema.primary = data.schema.primary
      }
      if (data.description?.length > 0) {
        dataset.description = data.description
      }
      if (data.tags?.length > 0) {
        dataset.tags = data.tags
      }

      parent.postMessage({
        name: 'scrollTo',
        payload: {
          x: 0,
          y: 0
        }
      },"*")
      this.loading = true
      this.$nioOpenApi.post('/datasets', dataset)
        .then((resp) => {
          if (resp.status > 199 && resp.status < 299) {
            this.$nioOpenApi.post(`/datasets/${resp.data.id}/activate`)
              .then(async res => {
                if (res.status > 199 && res.status < 299) {
                  try {
                    parent.postMessage({
                    name: 'hubspotAnalyticsEvent',
                      payload: {
                        eventName: 'datasetManagerDatasetCreated',
                        params: {
                          datasetName: resp.data.name,
                          date: resp.data.created_at
                        }
                      }
                    }, "*")
                  } catch {}    
                  this.createdDataset = res.data
                  this.createAttributeMappings(this.createdDataset.id).then(errors => {
                    this.loading = false
                    if (errors.length < 1) {
                      this.successDialog = true
                    } 
                  })
                } else {
                  this.loading = false
                  parent.postMessage({
                    name: 'pageNavigation',
                    payload: `error/${JSON.stringify(this.defaultErrorMessage)}`
                  },"*")
                }
              }, err => {
                console.log(err)
                this.loading = false
                parent.postMessage({
                  name: 'pageNavigation',
                  payload: `error/${encodeURIComponent(JSON.stringify(err))}`
                },"*")
              })
          } else {
            this.loading = false
            parent.postMessage({
              name: 'pageNavigation',
              payload: `error/${JSON.stringify(this.defaultErrorMessage)}`
            },"*")
          }
        }, err => {
          this.loading = false
          parent.postMessage({
            name: 'pageNavigation',
            payload: `error/${encodeURIComponent(JSON.stringify(err))}`
          },"*")
        })
      }
    },
    async createAttributeMappings(datasetId) {
      const mappings = makeAttributeMappings(datasetId, this.attributeMappings, this.getFieldsAsArray, this.attributes)
      const errors = []
      const promises = mappings.map(mapping => {
        return this.$nioOpenApi.post(`mappings/companies/${this.nioUser.companyId}`, mapping)
      })
      const resp = await Promise.all(promises.map(p => p.catch(err => {
        errors.push(err.response.data.error_description);
      })))
      if (errors.length > 0) {
        this.attributeMappingsErrorDialog = true
        this.attributeMappingsErrors = errors
        this.attributeMappings = []
      }
      return Promise.resolve(errors)
    },
    handleSuccessDialogAction(action) {
      this.successDialog = false
      switch (action) {
        case 'addFiles':
          this.ingestionUploadDialog = true
          break;
        case 'sources':
          parent.postMessage({
            name: 'pageNavigation',
            payload: 'sources'
          },"*")
          break;
        case 'datasets':
          parent.postMessage({
            name: 'pageNavigation',
            payload: 'datasets'
          },"*")
          break;
        default:
          break;
      }
    },
    scrollToStep(stepIndex) { 
      this.$nextTick(() => {
        const top = 35 + stepIndex * 130
        parent.postMessage({
          name: 'scrollTo',
          payload: {
            x: 0,
            y: top
          }
        },"*")
      })       
    },
    changeDatasetMethodType() {
      this.completedSteps = []
      this.stepPayloads.fields = null
      this.setFieldsFromJson({properties: {}})
      this.stepPayloads.activate = null
    },
    changeFileType() {
      this.completedSteps = ['dataset']
      this.stepPayloads.fields = null
      this.setFieldsFromJson({properties: {}})
      this.stepPayloads.activate = null
    },
    updateAttributeMappings(mappings) {
      this.attributeMappings = mappings
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.new-page
  padding: 1.5rem
  .header
    display: flex
    justify-content: space-between
    align-items: flex-start
    position: relative
    margin-bottom: 2rem
    .nio-button
      position: absolute
      right: 2.5rem
  ::v-deep .nio-step-header-slat
    padding-top: 1.375rem
  .nio-divider
    margin-top: -1.25rem
  ::v-deep .v-expansion-panel-content__wrap
    padding: 0rem    
  ::v-deep .nio-step-content-body
    position: relative
    .creating-subscription
      width: 100%
      height: 100%
      position: absolute
      .v-progress-circular
        position: relative
        left: 50%
        top: 6.25rem
        margin-left: -2.5rem
        z-index: 2
  .description-summary
    display: flex
    flex-direction: column
    p
      margin-top: 0.25rem
    .tag
      margin-right: 0.5rem
  .nio-step-name-offers
    ::v-deep .nio-summary-slat
      padding: 0rem
    ::v-deep .offers-summary
      width: 100%
      width: 40rem
      .offer
        padding: 1rem 1.5rem
        display: flex
        justify-content: space-between
      .offer + .offer
        border-top: 0.0625rem solid $c-primary-lighter
</style>