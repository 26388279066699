<template lang="pug">
  .edit-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-warning"
    )
    h3.nio-h3.text-primary-darker Proceed with caution
    p.nio-p.text-primary-dark Editing the fields in this section will trigger a re-evaluation of your input file based on the new settings. Be sure that the settings are correct for your input files or errors may occur during the data ingestion process.
    .actions
      NioButton(
        normal-secondary
        @click="cancel"
      ) Cancel
      NioButton(
        caution-filled-prepend
        @click="confirm"
      ) Continue
</template>

<script>


export default {
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    confirm() {
      this.$emit('confirm')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.edit-dialog
  padding: 100px 24px 78px 24px
  background-color: $c-white
  border: 1px solid $c-primary-lighter
  border-radius: 12px
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 16px
  h3
    margin-bottom: 24px
  p
    margin-bottom: 76px
    text-align: center
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + * 
      margin-left: 16px  
</style>