<template lang="pug">
  .success-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-sources"
    )
    h3.nio-h3.text-primary-darker Success!
    p.nio-p.text-primary-dark Your dataset has been created.
    p.nio-p.text-primary-dark Narrative has successfully inferred your dataset’s schema and you are now ready to upload files.
    .actions
      NioButton(
        normal-secondary
        @click="handleAction('addFiles')"
      ) Small File Upload
        NioTooltip(
          heading="Small File Uploads"
          message="Add small dataset files directly through Dataset Manager."
          open-on-hover
        )
      NioButton(
        normal-secondary
        @click="handleAction('sources')"
      ) S3 Ingestion
        NioTooltip(
          heading="S3 Ingestion"
          message="Learn how to send large dataset files directly to S3."
          link-text="more info"
          link-href="https://kb.narrative.io/how-do-i-ingest-data-using-my-managed-bucket"
          open-on-hover
        )
      NioButton(
        normal-secondary
        @click="handleAction('datasets')"
      ) View Your Datasets
</template>

<script>

export default {
  methods: {
    handleAction(action) {
      this.$emit('action', action)
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.success-dialog
  padding: 6.25rem 1.5rem 4.875rem 1.5rem
  background-color: $c-white
  border: 0.0625rem solid $c-primary-lighter
  border-radius: 0.75rem
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 1rem
  h3
    margin-bottom: 0.5rem
  p    
    text-align: center
    margin-bottom: 2.5rem
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + * 
      margin-left: 1rem  
</style>