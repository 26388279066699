<template lang="pug">
  .dataset-step
    .header.step-header
      h2.nio-h2.text-primary-darker Define Settings
    .step-loading(v-if="loading")
      v-progress-circular.progress(
        size="80"
        color="#1438F5"
        indeterminate
      )
    nio-divider(horizontal-solo)
    .warn-align
      NioAlert(
        v-if="!validDelimiter"
        :dismissable="validDelimiter"
        :visible="!validDelimiter"
        message="Unsupported delimiter detected."
        warning
      )
      NioButton.edit(
        v-if="(file !== null && prePopulate !== null && prePopulate !== undefined)"
        :disabled="editing"
        normal-primary
        @click="editDialog = true"
      ) {{ editing ? 'editing' : 'edit' }}
    .file-definitions
      .filter
        .title-description
          .filter-title.nio-h4.text-primary-darker File Type
          .description.nio-p.text-primary-dark The way your data is stored.
        .filter-value
          NioSelect.select(
            v-model="tempType"
            :items="fileTypes"
            :disabled="!editing && (prePopulate !== null && prePopulate !== undefined)"
            item-text="name"
            item-value="value"
            label="File Type"
          )
      .csv(v-if="type === 'flat'")
        .filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Header
            .description.nio-p.text-primary-dark Indicates whether the first row of a file contains field names.
          .filter-value
            NioSwitch(
              v-model="incHeader"
              :disabled="!editing && (file !== null && prePopulate !== null && prePopulate !== undefined)"
              label="Header"
            )
        .filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Delimiter
            .description.nio-p.text-primary-dark The character separating the data in your file into distinct fields.
          .filter-value
            NioSelect(
              v-model="delimiter"
              :items="delimiters"
              :disabled="!editing && (prePopulate !== null && prePopulate !== undefined)"
              item-text="name"
              item-value="value"
              label="Delimiter"
            )
        .filter(v-if="delimiter === 'Custom'")
          .title-description
            .filter-title.nio-h4.text-primary-darker Custom Delimiter
            .description.nio-p.text-primary-dark The character(s) separating the data in your file into distinct fields.
          .filter-value
            NioTextField(
              v-model="customDelimiter"
              label="Custom Delimiter"
            )
        .filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Escape Character
            .description.nio-p.text-primary-dark The single character used for escaping quotes inside an already quoted value.
          .filter-value
            NioTextField(
              v-model="escChar"
              :disabled="!editing && (prePopulate !== null && prePopulate !== undefined)"
              label="Escape Character"
            )
            p.nio-p.text-error(v-if="escChar.length > 1") Field restricted to a single character
        .filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Quote
            .description.nio-p.text-primary-dark The single character used for escaping quoted values where the separator can be part of the value.
          .filter-value
            nio-text-field(
              v-model="quoteType"
              :disabled="!editing && (prePopulate !== null && prePopulate !== undefined)"
              label="Quote Type"
            )
            p.nio-p.text-error(v-if="quoteType.length > 1") Field restricted to a single character
      .writeMode
        .filter
          .title-description
            .filter-title.nio-h4.text-primary-darker Write Mode
            .description.nio-p.text-primary-dark Specifies how to treat new data added to your dataset.
          .filter-value
            NioSelect(
              v-model="writeMode"
              :items="writeModes"
              :disabled="!editing && (prePopulate !== null && prePopulate !== undefined)"
              item-text="name"
              item-value="value"
              label="Write Mode"
            )
    NioDialog(
      v-model="changeFileTypeDialog"
    )
      ChangeFileTypeDialog(
        @cancel="changeFileTypeDialog = false; tempType = type"
        @confirm="confirmChangeFileType"
      )
    NioDialog(
      v-model="editDialog"
    )
      EditDialog(
        @cancel="editDialog = false"
        @confirm="confirmEditDialog"
      )
    NioDialog(
      v-model="errorDialog"
    )
      ErrorDialog(
        @close="errorDialog = false"
      )
</template>

<script>

import { NioAlert } from '@narrative.io/tackle-box'
import ChangeFileTypeDialog from './ChangeFileTypeDialog'
import EditDialog from './EditDialog'
import ErrorDialog from './ErrorDialog'
import { mapActions } from 'vuex'

export default {
  components: { NioAlert, ChangeFileTypeDialog, EditDialog, ErrorDialog },
  props: {
    "completedSummary": { type: Object, required: false },
    "prePopulate": { type: Object, required: false },
    "payload": { type: Object, required: false },
    "file": {type: File, required: false },
    "completedSteps": { type: Array, required: true },
    "disableControls": { type: Boolean, required: false, default: false },
    "externalEditingTrigger": { type: Boolean, required: false }
  },
  data() {
    return {
      loading: true,
      reInfer: false,
      newData: undefined,
      type: 'flat',
      tempType: 'flat',
      delimiter: ',',
      customDelimiter: undefined,
      escChar: '\"',
      quoteType: "\"",
      writeMode: 'append',
      incHeader: false,
      validDelimiter: true,
      fileTypes: [{
        name: 'CSV',
        value: 'flat'
      }, {
        name: 'JSON',
        value: 'json'
      },
      {
        name: 'Parquet',
        value: 'parquet'
      }
      ],
      delimiters: [{
        name: 'Pipe - "|"',
        value: '|'
      }, {
        name: 'Comma - ","',
        value: ','
      }, {
        name: 'Semi-colon - ";"',
        value: ';'
      }, {
        name: 'Tab - "\\t"',
        value: '\t'
      }, {
        name: 'Space - " "',
        value: ' '
      }, {
        name: 'Tilde - "~"',
        value: '~'
      }, {
        name: 'Custom',
        value: 'Custom'
      }],
      writeModes: [{
        name: 'Incremental updates',
        value: 'append'
      }, {
        name: 'Complete file updates',
        value: 'overwrite'
      }],
      changeFileTypeDialog: false,
      editing: false,
      editInferred: false,
      editDialog: false,
      errorDialog: false
    }
  },
  computed: {
    prepStepChanged() {
      let rtn = {
        fileInfo: {}
      }
      if (!this.writeMode) {
        return undefined
      }
      if (this.type === 'json') {
        rtn.fileInfo = { type: this.type, writeMode: this.writeMode }
      } else {
        rtn.fileInfo = {
          type: this.type,
          writeMode: this.writeMode,
          delimiter: this.delimiter,
          escapeCharacter: this.escChar,
          quote: this.quoteType,
          incHeader: this.incHeader
        }
      }
      if (this.newData !== undefined && this.editing) {
        rtn.fields = this.newData
      }

      return rtn
    }
  },watch: {
    prePopulate: {
      deep: true,
      handler(val) {
        this.handlePrePopulateChange()
      }
    },
    type: {
      deep: true,
      handler(val) {
        this.validateAndEmit()
      }
    },
    delimiter: {
      deep: true,
      handler() {
        if (!this.validDelimiter) {
          this.validDelimiter = this.delimiters.findIndex(el => el.value === this.delimiter) > -1
        }
        this.handleFieldChange()
      }
    },
    customDelimiter: {
      handler() {
        this.handleFieldChange()
      }
    },
    quoteType: {
      deep: true,
      handler() {
        this.handleFieldChange()
      }
    },
    escChar: {
      deep: true,
      handler() {
        this.handleFieldChange()
      }
    },
    writeMode: {
      deep: true,
      handler() {
        this.handleFieldChange()
      }
    },
    incHeader: {
      deep: true,
      handler() {
        this.handleFieldChange()
      }
    },
    newData: {
      deep: true,
      handler() {
        this.handleFieldChange()
      }
    },
    tempType(val) {
      if (this.completedSteps.includes('file info') && !this.editing) {
        this.changeFileTypeDialog = true
      } else {
        this.setDefaultFieldValues()
        this.type = val
        this.setDatasetType(val)
      }
    },
    externalEditingTrigger(val) {
      if (!val) {
        this.editing = false
      }
    }
  },
  mounted() {
    this.loading = false
    // TODO: Check if unexpected characters throw things off.
    if (this.prePopulate) {
      this.handlePrePopulateChange()
    } else {
      this.validateAndEmit()
    }
  },
  methods: {
    ...mapActions(['setDatasetType']),
    handleFieldChange() {
      if (this.editing && !this.editInferred) {
        this.reInferSchema().then((res) => {
          if (res.data?.schema) {
            let data = res.data.schema
            delete data.file_config
            this.newData = data
            if (this.editing) {
              this.newData.editing = true
            } else {
              this.newData.editing = false
            }
            this.editInferred = true
            this.validateAndEmit()
          } else {
            this.errorDialog = true
            this.$emit('stepPayloadChanged', null)
          }

        }, err => {
         this.errorDialog = true
         this.$emit('stepPayloadChanged', null)
        })
      } else if (!this.editInferred) {
        this.validateAndEmit()
      } else {
        this.editInferred = false
      }
    },
    validateAndEmit() {
      switch(this.type) {
        case 'json':
          if (this.validateJson()) {
            this.$emit('stepPayloadChanged', this.prepStepChanged)
          } else {
            this.$emit('stepPayloadChanged', undefined)
          }
          break
        case 'flat':
          if (this.validateFlat()) {
            this.$emit('stepPayloadChanged', this.prepStepChanged)
          } else {
            this.$emit('stepPayloadChanged', undefined)
          }
          break
        default:
          this.$emit('stepPayloadChanged', this.prepStepChanged)
      }
    },
    validateFlat() {
      if (this.prepStepChanged.fileInfo?.delimiter?.length === 0
        || this.prepStepChanged.fileInfo?.quote?.length === 0
        || this.prepStepChanged.fileInfo?.quote?.length > 1
        || this.prepStepChanged.fileInfo?.escapeCharacter?.length === 0
        || this.prepStepChanged.fileInfo?.escapeCharacter?.length > 1
        || !this.validDelimiter
      ) {
        return false
      }
      return true
    },
    reInferSchema() {
      // NB: in the case that any of the conditionals return false, we return a "dangling" Promise
      // that does not get resolved or rejected. As of 2023-12-10 we've accidentally come to rely
      // on this behaviour to avoid showing users an error dialog while they are editing their flat
      // file schema and are temporarily in an invalid state (e.g. changing the escape character).
      // We're choosing not to fix this behaviour as part of sc-28155 as Dataset Manager is on the
      // deprecation path. If you're reading this in a future where it's clear that Dataset Manager
      // will not be deprecated, then beware.
      return new Promise((resolve, reject) => {
        if (this.file !== undefined && this.prePopulate !== undefined && this.prePopulate !== null) {
          if ( this.validateFlat() &&
            (this.prePopulate.escapeCharacter !== this.escChar
            || this.prePopulate.delimiter !== this.delimiter
            || this.prePopulate.quote !== this.quoteType
            || this.prePopulate.escChar !== this.escChar
            || this.prePopulate.incHeader !== this.incHeader)
          ) {
              // THINGS CHANGED -> Re-infer
              // TODO: sync call to API and $emit fields??
              this.reInfer = true
              const formData = new FormData()
              if (this.type === 'flat') {
                formData.append("csv-header", this.incHeader)
                formData.append("csv-delimiter",this.delimiter === 'Custom' ? this.customDelimiter: this.delimiter)
                formData.append("csv-quote", this.quoteType)
                formData.append("csv-quote-escape", this.escChar)
              }
              formData.append("file", this.file)
              this.$nioOpenApi.post('/v2/inference', formData)
                .then(res => {
                  resolve(res)
                }, err => {
                  reject(err)
                })
            }
          }
      })
    },
    validateJson() {
      return true
    },
    confirmChangeFileType() {
      this.changeFileTypeDialog = false
      this.type = this.tempType
      this.$emit('changeFileType')
      this.validateAndEmit()
    },
    handlePrePopulateChange() {
      if (this.file !== undefined && this.prePopulate) {
        this.tempType = this.prePopulate.type // will trigger watcher and update this.type as well
        this.escChar = this.prePopulate.escapeCharacter
        this.validDelimiter = this.delimiters.findIndex(el => el.value === this.prePopulate.delimiter) > -1
        this.delimiter = this.prePopulate.delimiter
        this.incHeader = this.prePopulate.incHeader
        this.quoteType = this.prePopulate.quote
        this.writeMode = this.prePopulate.writeMode
      } else { // set default if not defined
        this.tempType = 'flat'
        this.setDefaultFieldValues()
      }
    },
    setDefaultFieldValues() {
      this.delimiter = ','
      this.escChar = '\"'
      this.quoteType = "\""
      this.writeMode = 'append'
      this.incHeader = false
    },
    confirmEditDialog() {
      this.editing = true
      this.editDialog = false
      this.$emit('editSelected')
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.dataset-step

  ::v-deep .expanded-row
    padding: 1.5rem 1.5rem 2rem 1.5rem
    background-color: $c-canvas
    .tags
      margin-top: 12px
      margin-left: -6px
    .tags > .nio-pill
      margin-left: 6px
  ::v-deep .nio-slat-title
    max-width: 500px
    white-space: pre-wrap !important

  .options
    display: flex
    justify-content: space-around

    .opt-card
      display: flex
      align-items: center
      background-color: $c-canvas
      border: 2px solid $c-primary-lighter
      border-radius: 12px
      padding: 1rem
      margin: 1rem
      width: 100%

      .icon
        display: flex
        justify-content: center
        align-items: center
        min-width: 4rem
        min-height: 4rem

    .selected
      border-color: $c-primary
  .dataset-definitions
    width: 100%
    margin-top: 1rem
    margin-bottom: 1rem

  .csv
    .filter
      &:first-child
        border-radius: 0px !important
      &:last-child
        border-radius: 0px !important
        border-bottom: 0px
  .warn-align
    display: flex
    justify-content: flex-end
    align-items: center
    margin-bottom: 1rem

    .nio-alert.nio-visible
      margin-bottom: 0

    .v-btn
      margin-left: 2rem
  .filter
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 1rem
    align-items: center
    width: 100%
    border: 1px solid $c-primary-lighter
    border-bottom: 0px
    padding: 1rem
    &:first-child
      border-radius: 12px 12px 0 0

    &:last-child
      border-radius: 0 0 12px 12px
      border-bottom: 1px solid $c-primary-lighter

    .filter-value
      width: 100%
      .nio-text-field
        margin-bottom: 0
  .file-definitions
    width: 100%
    position: relative
    .edit
      position: absolute
      top: -70px
      right: 0

  .csv
    .filter
      &:first-child
        border-border-radius: 0 0 0 0
</style>
