<template lang="pug">
  .review-step
    .header.step-header
      h2.nio-h2.text-primary-darker Review and activate
    .step-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .review
      .preview
        ReviewDataComponent(:reviewData="reviewData")
      .message.nio-p-large.text-primary-dark Click <span class="nio-bold">Activate</span> below to create your dataset. Note that datasets cannot be edited after being created at this time. If you need to make changes, you will need to create a new dataset.
</template>

<script>

import ReviewDataComponent from '@/shared/components/ReviewDataComponent'

export default {
  components: { ReviewDataComponent },
  props: {
    reviewData: { type: Object, required: true }
  },
  data: () => ({
    loading: true,
    accepted: false,
    fileTypes: [{
      name: 'CSV',
      value: 'flat'
    }, {
      name: 'JSON',
      value: 'json'
    }],
    specialCharacters: [{ 
      name: 'Pipe - "|"',
      value: '|'
    }, {
      name: 'Comma - ","',
      value: ','
    }, {
      name: 'Semi-colon - ";"',
      value: ';'
    }, {
      name: 'Tab - "\t"',
      value: '\t'
    }, {
      name: 'Space - " "',
      value: ' '
    },
    {
      name: 'Quote - \"',
      value: '"'
    },
    {
      name: 'Tilde - ~',
      value: '~'
    }],
    writeModes: [{
      name: 'Incremental updates',
      value: 'append'
    }, {
      name: 'Complete file updates',
      value: 'overwrite'
    }],
    status: 'pending',
    uploadPath: 'upload'
  }),
  mounted() {
    this.loading = false
    this.$emit('stepPayloadChanged', true)
  },
  methods: {
    getReadableString(property, value) {
      let specialChar = this.specialCharacters.find(char => char.value === value)  
      switch (property) {
        case 'type':
          return value === 'json' ? 'JSON' : 'CSV'
        case 'delimiter':
          return this.specialCharacters.find(char => char.value === value).name   
        case 'escapeCharacter':
          return specialChar ? specialChar.name : value 
        case 'incHeaders':
          return value ? 'Yes' : 'No'  
        case 'quote':
          return specialChar ? specialChar.name : value
        case 'writeMode': 
          return this.writeModes.find(mode => mode.value === value)?.name
        default:
          return value
      }
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.review-step
  
  .terms
    display: flex
    flex-direction: column
    align-items: flex-start
    padding: 32px
    background-color: $c-white
    border: 1px solid $c-primary-lighter
    border-radius: 12px
    ::v-deep label
      color: $c-primary-dark
  .preview
    border: 1px solid $c-primary-lighter
    border-radius: 12px
    padding-left: 24px
  .message
    margin: 48px 48px 0px 48px
    text-align: center
</style>
