<template lang="pug">
  .error-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-warning"
    )
    h3.nio-h3.text-primary-darker Something went wrong...
    p.nio-p.text-primary-dark There was an error reading your file. Please try again with a different file. If the problem persists, please contact the Narrative support team.
    p.nio-p.text-primary-dark Narrative uses the JSON Lines standard when inferring the dataset schema from the files you provide. To learn more about the JSON Lines format, visit <a href="https://jsonlines.org/" target="_blank">https://jsonlines.org/</a>
    .actions
      NioButton(
        normal-secondary
        @click="close"
      ) Back
</template>

<script>

export default {
  methods: {
    close() {
      this.$emit('close')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.error-dialog
  padding: 100px 24px 78px 24px
  background-color: $c-white
  border: 1px solid $c-primary-lighter
  border-radius: 12px
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 16px
  h3
    margin-bottom: 24px
  p    
    margin-bottom: 24px
    text-align: center
    max-width: 500px
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + * 
      margin-left: 16px  
</style>