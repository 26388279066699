import { render, staticRenderFns } from "./SuccessDialog.vue?vue&type=template&id=b6ee6f1a&scoped=true&lang=pug&"
import script from "./SuccessDialog.vue?vue&type=script&lang=js&"
export * from "./SuccessDialog.vue?vue&type=script&lang=js&"
import style0 from "./SuccessDialog.vue?vue&type=style&index=0&id=b6ee6f1a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6ee6f1a",
  null
  
)

export default component.exports