<template lang="pug">
  .invalid-names-dialog(ref="dialog")
    NioIconFramer(
      icon-name="display-warning"
    )
    h3.nio-h3.text-primary-darker Heads up! We've changed some field names
    p.nio-p.text-primary-dark We detected one or more field names that do not meet Narrative's constraints. The following invalid names have been changed to meet Narrative's ingestion requirements: 
    .invalid-names
      .invalid-name(v-for="name in invalidNames")
        .nio-p.text-primary-darker <i>{{ name.origName }}</i>
        .nio-p.text-primary-dark.label has been changed to 
        .nio-p.text-primary-darker <i>{{ name.newName }}</i>
    p.nio-p.text-primary-dark Field names must contain only alphanumeric chracters and underscores, and must be less than 256 characters in length. Keep in mind that during ingestion, all field names in source files must match those configured during dataset creation for ingestion to proceed without errors.
    .actions
      NioButton(
        normal-secondary
        @click="close"
      ) Ok
</template>

<script>

export default {
  props: {
    "invalidNames": { type: Array, required: true }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
};
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.invalid-names-dialog
  padding: 100px 24px 78px 24px
  background-color: $c-white
  border: 1px solid $c-primary-lighter
  border-radius: 12px
  display: flex
  flex-direction: column
  align-items: center
  .nio-icon-framer
    margin-bottom: 16px
  h3
    margin-bottom: 8px
  p    
    margin-bottom: 40px
    text-align: center
    max-width: 650px
  .actions
    display: flex
    justify-content: center
    align-items: center
    & > * + * 
      margin-left: 16px  
  .invalid-names
    padding: 24px
    border-radius: 12px
    border: 1px solid $c-primary-lighter
    margin: 0px auto 40px auto
    .invalid-name
      display: flex
      justify-content: center
      .nio-p, .nio-p i
        max-width: 320px
        white-space: pre-wrap
        word-wrap: break-word
      .label
        display: flex
        align-items: center
      & > * + *
        margin-left: 8px
    & > * + * 
      margin-top: 16px
</style>