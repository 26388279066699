<template lang="pug">
  .fields-step(:class="{editing: editDialog}")
    .header.step-header
      h2.nio-h2.text-primary-darker Define Fields
    .step-loading(v-if="loading")
      v-progress-circular.progress(
        size="80" 
        color="#1438F5"
        indeterminate 
      )
    .empty-fields(v-if="getFieldsAsArray.length < 1")
      h2.nio-h2.text-primary-darkest Add fields to your dataset
      p.nio-p.text-primary-dark You have not set up any fields yet.
      NioButton(
        normal-secondary 
        @click="addField()"
      ) add field
    .fields(v-else)
      .fields-container
        .fields-header
          h3.nio-h3.text-primary-darker.text-uppercase {{ fieldType }}
          h3.nio-h3.text-primary-darker {{ fieldsCountString }}
        NioExpansionPanels(
          v-model="panel" 
          :multiple="false"
        )
          draggable(
            :list="fields"
            width="100%" 
            handle=".csv-grip"
            @end="endDrag"
          )
            .header-slot(v-for="(item, id) in fields" :key="item.order") 
              TreeMenu(
                v-if="fieldType === 'flat'" 
                :field="item" 
                :id="id" 
                :draggable="!isInferred"
                field-type="flat" 
                @showEditDialog="showEditDialog"
                @toggleChanged="toggleChanged($event)"
              )
              TreeMenu(
                v-else 
                :field="item" 
                :id="id" 
                :draggable="false"
                field-type="json" 
                @showEditDialog="showEditDialog"
                @toggleChanged="toggleChanged($event)"
              )
        .fields-footer
          NioButton(
            normal-secondary 
            @click="addField()"
          ) add field
          .nio-p.text-primary-dark
      .define-primary
        .filter
          h4.filter-title.nio-h4.text-primary-darker Primary Field
            p.description.nio-p.text-primary-dark The primary field is the single defining attribute in your dataset and describes the field that buyers will filter on most often.
            p.description.nio-p.text-primary-dark Only one field in a dataset can be set to primary.
          .filter-value
            NioSelect.select(
              v-model="primary" 
              :items="getPrimaryFieldOptions" 
              item-text="name" 
              item-value="value" 
              label="Primary"
            )
      UserProposedAttributeMappings(
        :properties="getFieldsAsArray"
        :attributes="attributes"
        @attributeMappingsChanged="attributeMappingsChanged($event)"
      )
      .validation-errors 
        .nio-p.text-error(v-for="error in validationErrors") {{ error.message }}
    NioDialog(
      v-model="editDialog" 
      max-width="960px"
    )
      .dialog
        .dialog-header
          h1.nio-h1.text-primary-darker(v-if="addingField") Add <span v-if="addingChildField">Child </span> Field
          h1.nio-h1.text-primary-darker(v-else-if="locEditField.name === 'nioArrayItemsField'") Edit Array Items
          h1.nio-h1.text-primary-darker(v-else) Edit Field
          .close-btn(@click="clearEdit")
            NioIcon(name="utility-times")
        .field-definition
          //- .filter(v-if="columnType === 'json'")
          //-   .title-description
          //-     .filter-title.nio-h4.text-primary-darker Nest Field
          //-     .description.nio-p.text-primary-dark If this field is contained inside a parent field
          //-   .filter-value
          //-     nio-switch(v-model="item.nestColumn" label="Nest this column")
          //-     nio-text-field(v-model="item.parentColumn" label="Parent Column" v-if="item.nestColumn")
          .filter(v-if="locEditField.name !== 'nioArrayItemsField'")
            .title-description
              .filter-title.nio-h4.text-primary-darker Name
              .description.nio-p.text-primary-dark Used to identify this field. Field names should not contain spaces or non-alphanumeric characters other than "_", e.g. use 
                strong Customer_ID 
                span instead of Customer I.D.
            .filter-value
              NioTextField(
                v-model="locEditField.name" 
                :rules="[validateName]"
                label="Name"
                validate-on-blur
              )
              //- p.nio-p.text-error(v-if="checkName(locEditField.name).length > 0") {{ checkName(locEditField.name) }}
          .filter(v-if="locEditField.name !== 'nioArrayItemsField'")
            .title-description
              .filter-title.nio-h4.text-primary-darker Description
              .description.nio-p.text-primary-dark Information about the field.
            .filter-value
              NioTextField(
                v-model="locEditField.description" 
                label="Description"
              )
          .filter.type
            .title-description
              .filter-title.nio-h4.text-primary-darker Primitive Type <nio-button class="edit-field-type-button" v-if="!allowEditFieldType" normal-tertiary @click="allowEditDialog = true">Edit</nio-button>
              .description.nio-p.text-primary-dark Defines the controls available for filtering this field.
            .filter-value
              NioSelect(
                v-model="locEditField.type" 
                :disabled="!allowEditFieldType"
                :items="pTypes" 
                label="Primitive Type"
                item-value="value"
                item-text="name"
              )
          .filter(v-if="locEditField.name !== 'nioArrayItemsField' && !getEditPath.includes('nioArrayItemsField')")
            .title-description
              .filter-title.nio-h4.text-primary-darker Required
              .description.nio-p.text-primary-dark If a record requires a value in this field to be ingested to your dataset.
            .filter-value
              NioSwitch(
                v-model="locEditField.is_optional" 
                :false-value="true" 
                :true-value="false"
                label="Require a value in this field for ingestion"
              )
          .filter(v-if="locEditField.name !== 'nioArrayItemsField' && !getEditPath.includes('nioArrayItemsField')")
            .title-description
              .filter-title.nio-h4.text-primary-darker Sellable
              .description.nio-p.text-primary-dark If this field can be purchased by customers.
            .filter-value
              NioSwitch(
                v-model="locEditField.is_sellable"
                label="Make this field sellable"
              )
          .filter(v-if="locEditField.name !== 'nioArrayItemsField' && !getEditPath.includes('nioArrayItemsField')")
            .title-description
              .filter-title.nio-h4.text-primary-darker Sensitive
              .description.nio-p.text-primary-dark If this field contains sensitive data.
            .filter-value
              NioSwitch(
                v-model="locEditField.is_sensitive" 
                label="The field contains sensitive data"
              )
          .filter.approximate-cardinality(v-if="locEditField.type !== 'boolean' && locEditField.type !== 'object' && locEditField.type !== 'array' && locEditField.name !== 'nioArrayItemsField'")
            .title-description
              .filter-title.nio-h4.text-primary-darker Approximate Cardinality (Optional)
              .description.nio-p.text-primary-dark The expected number of unique values for this field
            .filter-value
              NioTextField(
                v-model="locEditField.approximate_cardinality" 
                :rules="[validateApproximateCardinality]"
                label="Approximate Cardinality (Optional)"
                validate-on-blur
              )
          .filter.enum(v-if="locEditField.type !== 'boolean' && locEditField.type !== 'object' && locEditField.type !== 'array' && locEditField.type !== 'timestamptz' && locEditField.type !== 'double' && locEditField.name !== 'nioArrayItemsField'")
            .title-description
              .filter-title.nio-h4.text-primary-darker Field Values (Optional)
              .description.nio-p.text-primary-dark Rules defining accepted values for your dataset.
            .filter-value
              NioRadioGroup(
                v-model="locEditField.fieldValues" 
                slat
              )
                NioRadioButton(
                  label="Any" 
                  value="any"
                )
                NioRadioButton(
                  label="Predefined Values" 
                  value="enum"
                )
              NioTagsField(
                v-if="locEditField.fieldValues === 'enum'" 
                v-model="locEditField.enum" 
                :data-type="locEditField.type"
                label="Add predefined value"
              )
        .field-footer
          NioButton(
            variant="secondary" 
            size="small" 
            @click="clearEdit"
          ) Cancel
          NioButton.rbtn(
            variant="primary" 
            size="small" 
            :disabled="editFieldErrors.namRequired || editFieldErrors.nameValid || editFieldErrors.nameUnique || editFieldErrors.approximateCardinality"
            @click="submitChanges"
          ) Save and continue
    NioDialog(
      v-model="allowEditDialog" 
    )
      EditDialog(
        @cancel="allowEditDialog = false"
        @confirm="allowEditFieldType = true; allowEditDialog = false"
      )
    NioDialog(
      v-model="notifyForZipCode" 
    )
      ZipCodeDialog(
        @cancel="notifyForZipCode = false"
        @confirm="editZipCode"
      )      
</template>

<script>
import draggable from 'vuedraggable'
import TreeMenu from '@/shared/components/TreeMenu'
import EditDialog from '../fileinfo/EditDialog'
import ZipCodeDialog from '../fileinfo/ZipCodeDialog'
import UserProposedAttributeMappings from '@/shared/components/attribute-mappings/UserProposedAttributeMappings.vue'
import { NioButton } from '@narrative.io/tackle-box'
import { itemByPath } from '@/shared/utils/datasetConversions'
import { mapActions, mapGetters } from 'vuex'
import { convertLocalEditFieldToJson, countFieldsFromArray } from '@/shared/utils/datasetConversions'
// Matches io.narrative.datashops.protocol.Identifier, which mimics Spark's "regular" identifier grammer:
// https://spark.apache.org/docs/latest/sql-ref-identifier.html
const validFieldNameRegex = new RegExp("^[0-9a-zA-Z_]+$")

export default {
  components: { 
    draggable, 
    TreeMenu, 
    NioButton, 
    UserProposedAttributeMappings,
    EditDialog,
    ZipCodeDialog 
  },
  props: {
    "currentStepPayload": { type: Object, required: true },
    "completedSummary": { type: Object, required: false },
    "prePopulate": { type: Object, required: false },
    "attributes": { type: Array, required: false }
  },
  data: () => ({
    notifyForZipCode: false,
    zipCodeNames: ['zip', 'postalcode', 'zipcode', 'zip code', 'postal code'],
    zipCodeField: null,
    loading: true,
    items: null,
    defaultSelection: null,
    count: 0,
    panel: null,
    primary: null,
    invalidPrimary: ['double', 'object', 'array'],
    locEditField: {
      approximate_cardinality: null,
      fieldValues: 'any',
      description: '',
      enum: [],
      is_optional: true,
      is_sellable: true,
      is_sensitive: false,
      name: ''
    },
    editFieldErrors: {
      nameRequired: true,
      nameValid: true,
      nameUnique: true,
      approximateCardinality: true
    },
    tableColumns: [
      {
        name: "slat",
        props: {
          title: 'name',
          subtitle: 'description'
        }
      }
    ],
    fieldsCountString: '',
    previousEFieldType: null,
    fields: null,
    editDialog: false,
    allowEditFieldType: true,
    allowEditDialog: false
  }),
  computed: {
    ...mapGetters([
      'getFieldsAsArray', 
      'getFieldsAsJson', 
      'getPrimaryFieldOptions', 
      'getEditField', 
      'getEditPath', 
      'getFieldsValidation'
    ]),
    pTypes() {
      let primitiveTypes = [
        {
          name: 'Double',
          value: 'double'
        },
        {
          name: 'Long',
          value: 'long'
        },
        {
          name: 'String',
          value: 'string'
        },
        {
          name: 'Timestamptz',
          value: 'timestamptz'
        },
        {
          name: 'Boolean',
          value: 'boolean'
        }
      ]
      if (this.fieldType === 'json') {
        primitiveTypes.push({
          name: 'Object',
          value: 'object'
        })
        primitiveTypes.push({
          name: 'Array',
          value: 'array'
        })
      }
      return primitiveTypes
    },
    isInferred() {
      return Object.keys(this.prePopulate.properties).length > 0
    },
    fieldType() {
      return this.currentStepPayload['file info']?.type
    },
    isEditing() {
      return this.getEditField !== undefined
    },
    addingField() {
      return this.getEditPath.split('/').includes('nioNewField')
    },
    addingChildField() {
      const targetPath = this.getEditPath.split('/')
      targetPath.shift()
      return targetPath.includes('nioNewField') && targetPath.length > 1
    },
    validationErrors() {
      return this.getFieldsValidation.errors.filter(error => error.valid === false)
    }
  },
  watch: {
    primary() {
      this.emitPayload()
    },
    getFieldsAsArray: {
      deep: true,
      handler(val) {
        this.fields = val
        const fieldsCount = countFieldsFromArray(val)
        let fieldsCountString = `${fieldsCount.totalFields} field${fieldsCount.totalFields > 1 ? 's' : ''}`
        if (this.fieldType === 'json') {
          if (fieldsCount.nestedFields > 0) {
            fieldsCountString += `, ${fieldsCount.nestedFields} nested`
          }
        }
        this.fieldsCountString = fieldsCountString
        this.emitPayload()
      }
    },
    getEditField: {
      deep: true,
      handler(val) {
        if (!val || val.isNew) {
          this.locEditField = {
            approximate_cardinality: '',
            fieldValues: 'any',
            description: '',
            enum: [],
            is_optional: true,
            is_sellable: true,
            is_sensitive: false,
            name: '',
            type: 'string'
          }
        } else {
          this.previousEFieldType = val.type
          this.locEditField = {...val}
        }
      }
    },
    locEditField: {
      deep: true,
      handler(val) {
        if (val.type && val.type !== this.previousEFieldType) {
          this.locEditField.enum = []
        }
        this.previousEFieldType = val.type
        if (this.isEditing) {
          this.validateEditField()
        }
      }
    },
    getFieldsValidation: {
      deep: true,
      handler(val) {
        const errors = val.errors.find(error => error.valid === false)
        this.validationErrorMsg = errors?.length > 0 ? errors[0].message : ''
      }
    },
    prePopulate: {
      deep: true,
      handler(val) {
        this.setFieldsFromJson(this.prePopulate)
      }
    },
    editDialog(val) {
      if (val) {
        parent.postMessage({
          name: 'scrollTo',
          payload: {
            x: 0,
            y: 0
          }
        },"*")
      }
      this.setAllowEditFieldType()
    }
  },
  mounted() {
    if (this.prePopulate) {
      this.allowEditFieldType = false
      // Set fields if they already exist (Schema inference)
      this.setFieldsFromJson(this.prePopulate).then(() => {
        this.loading = false
        this.checkForZipCode()
      })
    } else {
      this.loading = false
    }
  },
  methods: {
    checkForZipCode() {
      const zipCodeNames = new Set(this.zipCodeNames)
      const field = this.fields.find(f => {
        return zipCodeNames.has(f.name) && f.type !== "string"
      })
      if(field) {
        this.notifyForZipCode = true
        this.zipCodeField = field
      } 
    },
    editZipCode() {
      this.notifyForZipCode = false
      this.editDialog = true
      this.locEditField = this.zipCodeField
    },
    ...mapActions(['setFieldsFromJson', 'setFieldToEdit', 'setEFieldData', 'setFieldsFromArray']),
    validateName(value) {
      if (this.editFieldErrors.nameRequired) {
        return 'required'
      } else if (this.editFieldErrors.nameValid) {
        return 'please enter a valid field name'
      } else if (this.editFieldErrors.nameUnique) {
        return 'a field with this name already exists in the parent object'
      } else {
        return true
      }
    },
    validateApproximateCardinality(value) {
      if (this.editFieldErrors.approximateCardinality) {
        return 'Must be a positive integer'
      } else {
        return true
      }
    },
    clearEdit() {
      this.editDialog = false
      this.setFieldToEdit(undefined)
    },
    submitChanges() {
      const targetPath = this.getEditPath.split('/')
      targetPath.shift() // remove first empty element
      let parentField
      let order
      if (targetPath.includes('nioNewField')) { 
        targetPath.pop()
        if (targetPath.length === 0) { // root level field
          const numProps = Object.keys({...this.getFieldsAsJson.properties}).length
          if (numProps > 0) {
            order = numProps
          } else {
            order = 0
          }
        } else { // we want to target the parent element
          parentField = itemByPath(this.getFieldsAsArray, targetPath) // get parent field
          if (parentField.properties && parentField.properties.length > 0) {
            order = parentField.properties.length
          } else {
            order = 0
          }
        }

      } else { // editing
        if (targetPath.length === 1) { // editing root level field
          order = this.getFieldsAsJson.properties[targetPath[0]].order 
        } else { // editing nested field
          targetPath.pop() // get parent field
          const parentField = itemByPath(this.getFieldsAsArray, targetPath)
          order = parentField.order
        }
      }
      this.setEFieldData(convertLocalEditFieldToJson(this.locEditField, order))
      this.clearEdit()
    },
    validateEditField() {
      // test name present
      let existingNames
      let parentField
      if (this.locEditField.name?.length > 0) {
        this.editFieldErrors.nameRequired = false
      } else {
        this.editFieldErrors.nameRequired = true
      }
      // test name regex
      if (validFieldNameRegex.test(this.locEditField.name) && this.locEditField.name.length > 0 && this.locEditField.name.length < 256) {
        this.editFieldErrors.nameValid = false
      } else {
        this.editFieldErrors.nameValid = true
      }
      // test approximate cardinality 
      const integerRegex = /^[1-9]+[0-9]*$/
      if (this.locEditField.approximate_cardinality?.length > 0 && !integerRegex.test(this.locEditField.approximate_cardinality)) {
        this.editFieldErrors.approximateCardinality = true
      } else {
        this.editFieldErrors.approximateCardinality = false
      }

      // test name is unique at level of nesting
      const targetPath = this.getEditPath.split('/')
      targetPath.shift() // remove first empty element
      if (targetPath.includes('nioNewField')) { 
        if (targetPath.length === 1) { // adding root level field
          existingNames = Object.keys({...this.getFieldsAsJson.properties}).map(name => name.toLowerCase())
        } else { // we want to target the parent element
          const parentPath = this.getEditPath.split('/')
          parentPath.shift()
          parentPath.pop()
          parentField = itemByPath(this.getFieldsAsArray, parentPath) // get parent field
          if (parentField.properties && parentField.properties.length > 0) {
            existingNames = parentField.properties.map(property => {
              return property.name.toLowerCase()
            })
          }
        }
        if (existingNames?.includes(this.locEditField.name.toLowerCase())) {
          this.editFieldErrors.nameUnique = true
        } else {
          this.editFieldErrors.nameUnique = false
        }
      } else { // editing
        const originalFieldName = targetPath[targetPath.length - 1].toLowerCase()
        if (targetPath.length === 1) { // editing root level field
          existingNames = Object.keys({...this.getFieldsAsJson.properties}).map(name => name.toLowerCase())   
        } else { // editing nested field
          targetPath.pop() // get parent field
          const parentField = itemByPath(this.getFieldsAsArray, targetPath)
          if (parentField.properties && parentField.properties.length > 0) {
            existingNames = parentField.properties.map(property => {
              return property.name.toLowerCase()
            })
          } else {
            existingNames = []
          }
        }
        if (existingNames.includes(this.locEditField.name.toLowerCase()) && !(this.locEditField.name.toLowerCase() === originalFieldName)) {
          this.editFieldErrors.nameUnique = true
        } else {
          this.editFieldErrors.nameUnique = false
        }
      }
    },
    //  TODO: This is the current regex test, and name length test used to make sure names are valid.
    isValidFieldName(name) {
      return validFieldNameRegex.test(name) && name.length > 0 && name.length < 256;
    },
    addField() {
      this.setFieldToEdit('/nioNewField' )
      this.editDialog = true
    },
    emitPayload() {
      if (!this.getFieldsValidation.errors.find(error => error.valid === false)) {
        this.$emit('stepPayloadChanged', {
          properties: this.getFieldsAsArray,
          primary: this.primary,
          fieldsCountString: this.fieldsCountString
        })
      } else {
        this.$emit('stepPayloadChanged', null)
      }
    },
    endDrag(event) {
      this.currentDraggedIndex = null
      this.setFieldsFromArray(this.fields.map((field, index) => {
        return {
          ...field,
          order: index
        }
      }))
    },
    showEditDialog() {
      this.editDialog = true
    },
    toggleChanged(event) {
      this.setFieldToEdit(event.path).then(() => {
        this.locEditField = this.getEditField
        if (event.type === 'sellable') {
          this.locEditField.is_sellable = event.value
        } else if (event.type === 'required') {
          this.locEditField.is_optional = !event.value
        } else if (event.type === 'sensitive') {
          this.locEditField.is_sensitive = event.value
        }
        this.submitChanges()
      })
    },
    setAllowEditFieldType() {
      if (this.prePopulate?.properties && Object.keys(this.prePopulate.properties).length > 0) {
        this.allowEditFieldType = false
      } else {
        this.allowEditFieldType = true
      }
    },
    attributeMappingsChanged(mappings) {
      this.$emit('attributeMappingsChanged', mappings)
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"
.fields-step.editing
  max-height: 0rem
.fields
  .nio-alert
    height: auto
    margin-top: 2rem
    margin-bottom: 2rem
  .fields-footer
    display: flex
    justify-content: flex-end
    border: 0.0625rem solid $c-primary-lighter
    border-top: 0
    padding: 3.125rem 1.5rem 1.5rem 1.5rem
    border-radius: 0 0 0.75rem 0.75rem
    background-color: white
  .fields-container
    padding: 1.5rem
    border: 0.0625rem solid $c-primary-lighter
    border-radius: 0.75rem
    display: flex
    flex-direction: column
    .fields-header
      display: flex
      justify-content: space-between
      align-items: flex-end
      margin-bottom: 1.5rem
    .nio-expansion-panels
      overflow: hidden
      border-bottom: 0rem
      div
        width: 100%
      &:first-child
      border-radius: 0.75rem 0.75rem 0 0
      ::v-deep .v-expansion-panel-header
        align-items: flex-start
      .header-slot
        display: flex
        flex-direction: column
        &.sortable-chosen .tree-menu
          background-color: $c-canvas
          outline: 0.125rem solid $c-primary-lighter
        .field-header
          display: flex
          justify-content: space-between
      .data
        display: flex
        justify-content: space-between
        margin-top: 1rem
        .h
          text-transform: uppercase
          font-weight: 600
        .t
          padding-top: 1rem
  .define-primary
    margin-top: 3rem
    border: 0.0625rem solid $c-primary-lighter
    border-radius: 0.75rem
    overflow: hidden
    background-color: white

    .filter
      display: grid
      grid-template-columns: 1fr 1fr
      grid-gap: 1rem
      align-items: center
      width: 100%
      padding: 1rem
      align-items: flex-start
      
      .filter-value
        width: 100%
        .nio-text-field
          margin-bottom: 0
  .user-proposed-attribute-mappings
    margin-top: 24px
  .validation-errors
    margin-top: 1.5rem
    .nio-p
      text-align: center
    & > * + *
      margin-top: 0.5rem
.dialog
  .dialog-header
    display: flex
    justify-content: space-between

  .field-definition
    background-color: white
    margin-top: 1.5rem
    .filter
      display: grid
      grid-template-columns: 1fr 1fr
      grid-gap: 1rem
      align-items: center
      width: 100%
      background-color: white
      border: 0.0625rem solid $c-primary-lighter
      border-bottom: 0rem
      padding: 1rem

      &:first-child
        border-radius: 0.75rem 0.75rem 0 0 

      &:last-child
        border-radius: 0 0 0.75rem 0.75rem
        border-bottom: 0.0625rem solid $c-primary-lighter
      
      .filter-value
        width: 100%
        .nio-text-field
          margin-bottom: 0
      &.enum .nio-tags-field
        margin-top: 0.5rem
      &.approximate-cardinality
        padding-bottom: 2rem // space for validation error
      &.type
        .filter-title
          display: flex
          .edit-field-type-button
            display: inline-block
            transform: translateY(-0.5313rem)
  .field-footer
    display: flex
    justify-content: flex-end
    align-items: center
    margin-top: 2rem
    .rbtn
      margin-left: 1rem
.empty-fields
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  border: 0.0625rem solid $c-primary-lighter
  border-radius: 0.75rem
  padding: 2rem
  
</style>
