<template lang="pug">
  .dataset-step
    .header.step-header
      h2.nio-h2.text-primary-darker Set Dataset Info
    .step-loading(v-if="loading")
      v-progress-circular.progress(
        size="80"
        color="#1438F5"
        indeterminate
      )
    .options
      .opt-card(
        :class="[type === 'manual' ? 'selected' : '']"
        @click="tempType = 'manual'"
      )
        .icon
          nio-icon-framer(
            icon-name="display-listmanager"
            small
          )
        .option-text
          h4.nio-h4.text-primary-darker Create Manually
          p.nio-p.text-primary-dark Enter information about your dataset
      .opt-card(
        :class="[type === 'file' ? 'selected' : '']"
        @click="tempType = 'file'"
      )
        .icon
          nio-icon-framer(
            icon-name="display-sources"
            small
          )
        .option-text
          h4.nio-h4.text-primary-darker Upload File
          p.nio-p.text-primary-darker Select a small .JSON or .CSV sample to help configure your dataset
    nio-divider(horizontal-solo)
    .dataset-definitions
      .filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Name
          .description.nio-p.text-primary-dark The displayed name of your dataset. This may be shown to prospective buyers.
        .filter-value
          nio-text-field(
            v-model="dataset.name"
            label="Dataset Name"
          )
      .filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Description
          .description.nio-p.text-primary-dark Information about your dataset. If you create a public Data Shop in
            //- TODO: dynamic linking based on environment
            a(
              href="https://app.narrative.io/app/shop-builder"
              target="_blank"
            ) Shop Builder
            span , we'll add a JSON-LD dataset schema to help buyers discover your dataset on search engines
        .filter-value
          nio-text-field(
            v-model="dataset.description"
            label="Description"
          )
      .filter
        .title-description
          .filter-title.nio-h4.text-primary-darker Tags
          .description.nio-p.text-primary-dark Used for categorization and discoverability across the platform. Use the return key to save a tag.
        .filter-value
          NioTagsField(
            v-model="dataset.tags"
            label="Add Tags"
          )
    .file-upload(v-if="type === 'file'")
      NioFileChooser(
        v-model="file"
        :state="uploaderState"
        :valid="validFileType"
        :percent-complete="progress"
        :max-file-size="1024*1024*10"
        instructions="Upload a .CSV or .JSON file formatted as Narrative should expect going forward"
        action-label="Generate Fields"
        success-msg="We did our best. Validate our results over the next steps."
        validation-error-msg="Your file does not contain any valid IDs."
        invalid-msg="Invalid file type."
        @changed="updateFiles($event)"
        @stateChanged="setState($event)"
        @actionClicked="attemptSchemaInference"
        @cancelClicked="cancel"
      )
        template(v-slot:error-actions)
          NioButton(
            normal-secondary
            @click="resetDownloader"
          ) Try again
        template(v-slot:success-actions)
          NioButton(
            normal-secondary
            @click="resetDownloader"
          ) Start over
    NioDialog(
      v-model="confirmResetDialog"
    )
      ConfirmResetDialog(
        @cancel="confirmResetDialog = false; tempType = type"
        @confirm="confirmResetDataset"
      )
    NioDialog(
      v-model="invalidNamesDialog"
    )
      InvalidNamesDialog(
        :invalid-names="invalidNames"
        @close="invalidNamesDialog = false"
      )
    NioDialog(
      v-model="errorDialog"
      class="full-dialog"
    )
      NioApiError(
        :error="error"
        @close="errorDialog = false"
      )
</template>

<script>

import { NioOpenApiModule, NioFileChooser } from '@narrative.io/tackle-box'
import ConfirmResetDialog from './ConfirmResetDialog'
import InvalidNamesDialog from './InvalidNamesDialog'
import { checkFieldNamesFromJson } from '@/shared/utils/datasetConversions'

export default {
  components: { NioFileChooser, ConfirmResetDialog, InvalidNamesDialog },
  props: {
    "completedSummary": { type: Object, required: false },
    "completedSteps": { type: Array, required: true}
  },
  data: () => ({
    error: '',
    loading: true,
    items: null,
    tableColumns: null,
    type: 'file',
    tempType: 'file',
    uploaderState: 'initial',
    inlineDownloaderState: 'initial',
    file: null,
    validFileType: false,
    completeUpload: false,
    progress: 0,
    iFormat: null,
    iSchema: null,
    dataset: {
      name: '',
      description:  '',
      permalink: 'fake',
      tags: []
    },
    confirmResetDialog: false,
    invalidNames: null,
    invalidNamesDialog: false,
    errorDialog: false,
  }),
  watch: {
    dataset: {
      deep: true,
      handler(val) {
        this.validateEmit()
      }
    },
    type() {
      this.validateEmit(true)
    },
    tempType(val) {
      if (this.completedSteps.includes('file info')) {
        this.confirmResetDialog = true
      } else {
        this.type = val
      }
    }
  },
  mounted() {
    this.loading = false
  },
  methods: {
    setState(val) {
      this.uploaderState = val
    },
    resetDownloader() {
      this.confirmResetDialog = true
    },
    iHandleFormat(payload) {
      this.iFormat = {
        type: payload.type,
        writeMode: 'append',
      }
      if (payload.type === 'flat') {
        this.iFormat.delimiter = payload.delimiter
        this.iFormat.escapeCharacter = payload.escape
        this.iFormat.incHeader = true
        this.iFormat.quote = payload.quote
      }
      return
    },
    iHandleSchema(payload) {
      if (payload.file_config) delete payload.file_config
      this.iSchema = payload
    },
    iSuccessResponse(resp) {
      this.progress = 100
      this.invalidNames = null
      if (resp.data?.schema || resp.data?.properties){
        const schema = resp.data.schema ? { properties: resp.data.schema.properties } : { properties: resp.data.properties}
        const fieldNamesValidation = checkFieldNamesFromJson({...schema.properties})
        const validatedSchema = {...fieldNamesValidation.properties}
        this.invalidNames = fieldNamesValidation.invalidNames
        const apiConfig = resp.data.schema?.file_config || resp.data?.file_config
        const format = apiConfig ? apiConfig : {type: 'json'}

        if (this.invalidNames?.length > 0) {
          this.invalidNamesDialog = true
        }

        this.iHandleFormat(format)
        this.iHandleSchema({properties: validatedSchema})
        this.completeUpload = true
        this.validateEmit()
        this.setState('success')
      } else {
         this.setState('error')
      }
    },
    iErrorResponse(err) {
      this.setState('error')
      this.errorDialog = true
      this.error = JSON.stringify(err)
      this.scrollToTop()
    },
    scrollToTop() {
      parent.postMessage({
          name: 'scrollTo',
          payload: {
            x: 0,
            y: 0
          }
        },"*")
    },
    attemptSchemaInference() {
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("id", 7878);
      this.$nioOpenApi.post('/v2/inference', formData).then(this.iSuccessResponse, this.iErrorResponse)
    },
    cancel() {
    },
    updateFiles(files) {
      this.file = files[0]
      this.validFileType = true
    },
    validateEmit(resetFields) {
      if (this.type === 'manual') {
        this.file = null
      }
      if (this.type === 'file' && !this.completeUpload) {
        this.$emit('stepPayloadChanged', null)
        return
      }
      if (this.dataset.name.length > 0) {
        this.$emit('stepPayloadChanged', this.prepEmitData(resetFields))
      } else {
        this.$emit('stepPayloadChanged', null)
      }
    },
    prepEmitData(resetFields) {
      let rtn = {}
      rtn.dataset = this.dataset
      rtn.type = this.type ? this.type : 'flat'
      if (this.file !== null) {
        rtn.file = this.type === 'file' ? this.file : undefined
        rtn.fileinfo = this.iFormat ? this.iFormat : undefined
        rtn.fields = this.iSchema && !resetFields ? this.iSchema : {properties: {}}
      }
      return rtn
    },
    confirmResetDataset() {
      this.confirmResetDialog = false
      this.type = this.tempType
      this.file = null
      this.setState('initial')
      this.$emit('changeDatasetMethodType')
      this.validateEmit()
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.dataset-step
  ::v-deep .expanded-row
    padding: 1.5rem 1.5rem 2rem 1.5rem
    background-color: $c-canvas
    .tags
      margin-top: 12px
      margin-left: -6px
    .tags > .nio-pill
      margin-left: 6px
  ::v-deep .nio-slat-title
    max-width: 500px
    white-space: pre-wrap !important

  .options
    display: flex
    justify-content: space-around

    .opt-card
      display: flex
      align-items: center
      background-color: $c-canvas
      border: 2px solid $c-primary-lighter
      border-radius: 12px
      padding: 1rem
      margin: 1rem
      width: 100%
      cursor: pointer

      .coming-tag
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%

      .icon
        display: flex
        justify-content: center
        align-items: center
        min-width: 4rem
        min-height: 4rem
        margin-right: 1.5rem
        .nio-icon-framer
          background: $c-primary
          border-color: $c-primary
          ::v-deep svg
            path
              stroke: white

    .selected
      border-color: $c-primary
    .disabled
      opacity: 0.5
      cursor: not-allowed
  .dataset-definitions
    width: 100%
    margin-top: 1rem
    margin-bottom: 1rem
  .filter
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 1rem
    align-items: center
    width: 100%
    border: 1px solid $c-primary-lighter
    border-bottom: 0px
    padding: 1rem
    &:first-child
      border-radius: 12px 12px 0 0

    &:last-child
      border-radius: 0 0 12px 12px
      border-bottom: 1px solid $c-primary-lighter

    .filter-value
      width: 100%
      .nio-text-field
        margin-bottom: 0
  .file-definitions
    width: 100%

  .full-dialog
    &.nio-dialog
      justify-content: flex-start
      align-items: flex-start
      flex-direction: column
      height: 100%
      padding: 0
    ::v-deep .nio-dialog-content
        width: 100% !important
        height: 100%
    ::v-deep .nio-dialog-scrim
      display: none
      backdrop-filter: none

</style>
