import { render, staticRenderFns } from "./DatasetStep.vue?vue&type=template&id=7a34552a&scoped=true&lang=pug&"
import script from "./DatasetStep.vue?vue&type=script&lang=js&"
export * from "./DatasetStep.vue?vue&type=script&lang=js&"
import style0 from "./DatasetStep.vue?vue&type=style&index=0&id=7a34552a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a34552a",
  null
  
)

export default component.exports